import React from "react"
import "_data/normalize.css"
import "@fontsource/montserrat/800.css"
import "@fontsource/montserrat/700.css"
import "@fontsource/montserrat/600.css"
import "@fontsource/montserrat/500.css"
import { motion } from "framer-motion"
import { Link } from "gatsby"
import PageHeadline from "_components/misc/pageHeadline"
import JoinListForm from "_components/forms/form_signup"
import Header from "_components/nav/header/_header"
import Seo from "_components/Seo/beta"
import Footer from "_components//nav/footer/_footer"
import { color_textLink } from "_src/assets/data/global_colors"
import {
  Paragraph,
  Screen,
  Content,
  HeaderContainer,
} from "_data/global_styles"

const JoinList = () => {
  return (
    <>
      <Seo />
      <HeaderContainer>
        <Header />
      </HeaderContainer>
      <Screen>
        <Content>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.65 }}
          >
            <PageHeadline copy="Sign-up to be notified of important changes and new features." />
            <div style={{ marginLeft: "1vw", color: "#303c6c" }}>
              <Paragraph>
                We are not currently requiring therapists to login before using
                our tools. However, that will change in the future. Please join
                our announcement list to be notified before that happens and for
                other important{" "}
                <Link
                  style={{
                    color: color_textLink,
                    fontWeight: "800",
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
                  to="/changelog"
                >
                  update information
                </Link>
                .
              </Paragraph>
              <JoinListForm />
            </div>
          </motion.div>
        </Content>
        <Footer />
      </Screen>
    </>
  )
}

export default JoinList
