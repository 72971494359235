import React, { useState, useEffect } from "react"
import { between } from "polished"
import { FormLabel, FormInput } from "_data/global_styles"
import { Logger } from "_data/global_functions.js"
import { useForm } from "react-hubspot"
import Button from "_components/buttons/button_button1"
import Modal from "_components/misc/modal_alert"
import styled from "styled-components"
import { color_formLabel } from "_src/assets/data/global_colors"

const NewForm = () => {
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [showFailModal, setShowFailModal] = useState(false)
  const [buttonText, setButtonText] = useState("Submit")

  // I removed data from below.  Check if signup form still works.
  const { isLoading, isError, handleSubmit } = useForm({
    portalId: "7430301",
    formId: "6d08cb70-b702-4be6-9f8d-bfdd7bca74cb",
  })

  const submitForm = (e) => {
    e.preventDefault()
    handleSubmit(e)
  }

  useEffect(
    (data) => {
      if (isError) {
        setShowSuccessModal(false)
        setShowFailModal(true)
      }
    },
    [isError]
  )

  useEffect(
    (data) => {
      if (isLoading) {
        setButtonText("Thank you!")
        Logger("Submitting Form")
        setShowSuccessModal(true)
      }
    },
    [isLoading]
  )

  return (
    <>
      <Form onSubmit={submitForm}>
        <Row>
          <Column>
            <InputContainer>
              <FormLabel style={{ color: color_formLabel, fontWeight: 800 }}>
                * First Name:{" "}
              </FormLabel>
              <FormInput name="firstname" type="text" required />
            </InputContainer>
          </Column>
          <Column>
            <InputContainer>
              <FormLabel style={{ color: color_formLabel, fontWeight: 800 }}>
                * Last Name:{" "}
              </FormLabel>
              <FormInput name="lastname" type="text" required />
            </InputContainer>
          </Column>
        </Row>
        <Row>
          <Column>
            <InputContainer>
              <FormLabel style={{ color: color_formLabel, fontWeight: 800 }}>
                * Email Address:{" "}
              </FormLabel>
              <FormInput name="email" type="email" required />
            </InputContainer>
          </Column>
          <Column>
            <InputContainer>
              <FormLabel style={{ color: color_formLabel, fontWeight: 800 }}>
                * Website URL:{" "}
              </FormLabel>
              <FormInput name="website" type="text" required />
            </InputContainer>
          </Column>
        </Row>
        <Row>
          <Column>
            <ButtonContainer style={{ marginTop: "1vh" }}>
              <Button isForm="true" copy={buttonText} />
            </ButtonContainer>
          </Column>
        </Row>
        <Request>
          <CheckBox
            type="checkbox"
            name="beta_request"
            value="Yes"
            checked="checked"
            readOnly
          />
          <FormLabel style={{ color: color_formLabel, fontWeight: 800 }}>
            Keep me informed
          </FormLabel>
        </Request>
      </Form>

      {showSuccessModal === true && (
        <Modal
          return="/"
          title="Request Received"
          buttonMessage="Return to Homepage"
          message="Thank you for your interest!  You will soon be
            notified of changes by email."
        />
      )}

      {showFailModal === true && (
        <Modal
          return="this"
          title="Ohhh nooooo!!!"
          buttonMessage="Close and Try Again"
          message="There was a problem with one or more of your entries.  Please check that you correctly typed in your email address."
        />
      )}
    </>
  )
}

const Request = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1vh;
  @media only screen and (max-width: 500px) {
    justify-content: center;
  }
`
const CheckBox = styled.input`
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  margin-right: 0.5vw;
`
const Form = styled.form`
  font-family: montserrat;
  font-weight: 600;
  /* font-size: ${between("12px", "22px", "250px", "3840px")}; */
  font-size: 14px;
`
// const Error = styled.span`
//   color: #d20000;
// `
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`
const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  @media only screen and (max-width: 500px) {
    align-items: center;
  }
`
const InputContainer = styled.div`
  width: 90%;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media only screen and (max-width: 400px) {
    margin-top: 5px;
    margin-bottom: 5px;
  }
`
const ButtonContainer = styled.div`
  width: 200px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 400px) {
    margin-top: 5px;
    margin-bottom: 5px;
  }
`
export default NewForm
